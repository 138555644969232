import { styled } from 'styled-components';

import { CardType } from 'apps-common/types';

import AmericanExpressSVG from '../assets/CreditCards/amex.svg?react';
import DinersSVG from '../assets/CreditCards/diners.svg?react';
import DiscoverSVG from '../assets/CreditCards/discover.svg?react';
import JCBSVG from '../assets/CreditCards/jcb.svg?react';
import MasterCardSVG from '../assets/CreditCards/mastercard.svg?react';
import VisaSVG from '../assets/CreditCards/visa.svg?react';

const VisaIcon = styled(VisaSVG)`
  width: 25px;
  height: 13px;
`;

const MasterCardIcon = styled(MasterCardSVG)`
  width: 25px;
  height: 13px;
`;

const AmericanExpressIcon = styled(AmericanExpressSVG)`
  width: 25px;
  height: 13px;
`;

const DiscoverIcon = styled(DiscoverSVG)`
  width: 25px;
  height: 13px;
`;

const DinersIcon = styled(DinersSVG)`
  width: 25px;
  height: 13px;
`;

const JCBIcon = styled(JCBSVG)`
  width: 25px;
  height: 13px;
`;

interface CardIconProps {
  cardType: keyof typeof CardType;
}

const cardIcons = {
  Visa: <VisaIcon />,
  MasterCard: <MasterCardIcon />,
  AmericanExpress: <AmericanExpressIcon />,
  Discover: <DiscoverIcon />,
  Diners: <DinersIcon />,
  JCB: <JCBIcon />,
};

export const CreditCardIcon = ({ cardType }: CardIconProps) => cardIcons[cardType] ?? null;
