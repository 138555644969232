import { useMutation } from '@tanstack/react-query';

import { renewSubscription } from 'apps-common/graphql/renewSubscription';

export const useRenewSubscription = () => {
  return useMutation({
    mutationKey: ['renewSubscription'],
    mutationFn: renewSubscription,
  });
};
