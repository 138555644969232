import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import { Currency, Invoice } from 'apps-common/types';
import { axiosQuery } from 'apps-common/utils/axiosQuery';

export interface InvoiceResponse {
  account: {
    currency: Currency;
  };
  invoices: Invoice[];
}

const QUERY = gql`
  query BillingHistory {
    account {
      currency
    }
    invoices {
      id
      amount
      balance
      date
      compensationType
      compensationAmount
    }
  }
`;

export const useGetBillingHistory = () =>
  useQuery<InvoiceResponse>({
    queryKey: ['getBillingHistory'],
    queryFn: async () => axiosQuery<InvoiceResponse>('BillingHistory', QUERY),
    refetchOnWindowFocus: false,
    staleTime: 900000, //15min stale
  });
