import JSZip from 'jszip';

const createDownloadLink = (url: string, filename: string): void => {
  const link = document.createElement('A') as HTMLAnchorElement;
  link.href = url;
  link.download = filename;
  link.hidden = true;
  link.click();
  link.remove();
};

export const zipMultipleFiles = async (files: { data: File; fileName: string }[], zipFileName: string) => {
  const zip = new JSZip();

  files.forEach(({ data, fileName }) => {
    zip.file(fileName, data);
  });

  const zipFile = await zip.generateAsync({ type: 'blob' });
  clientDownloadFile(zipFile, 'application/zip', zipFileName);
};

export const clientDownloadFile = (
  file: Blob,
  contentType: 'application/pdf' | 'application/zip',
  fileName: string,
) => {
  const url = URL.createObjectURL(new Blob([file], { type: contentType }));
  createDownloadLink(url, fileName);
};
