import { HTMLAttributes, PropsWithChildren, forwardRef, useRef } from 'react';

import { styled } from 'styled-components';
import { t } from 'translations';

const GradientBg = styled.div(
  ({ theme }) => `
  font-size: ${theme.fontSizes.medium};
  line-height: 100%;
  border-radius: 1em;
  background: linear-gradient(180deg, #191a1d 0%, #373c42 100%);
`,
);

const Tablist = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  background: transparent;
`;

const TabButton = styled.button(
  ({ theme }) => `
  appearance: none;
  background: transparent;
  cursor: pointer;
  border: max(1px, 0.05em) solid transparent;
  border-radius: 1em;
  padding: 0.25em 0.75em;
  color: rgb(95 98 101 / 100%);
  z-index: 0;

  &:focus {
    z-index: 1;
    outline: max(2px, 0.1em) solid ${theme.colors.helsinkiBlue};
    outline-offset: max(2px, 0.05em);
  }

  &[aria-selected="true"] {
    background: rgb(72 78 86 / 100%);
    color: ${theme.colors.white};
    border: max(1px, 0.05em) solid ${theme.colors.grayDarkest};
  }
`,
);

interface TabProps {
  'aria-controls': HTMLAttributes<HTMLButtonElement>['aria-controls'];
  selected: boolean;
  onClick: HTMLAttributes<HTMLButtonElement>['onClick'];
}

const Tab = forwardRef<HTMLButtonElement, TabProps & PropsWithChildren>(({ selected, children, ...restProps }, ref) => (
  <TabButton {...restProps} ref={ref} role="tab" tabIndex={selected ? 0 : -1} aria-selected={selected}>
    {children}
  </TabButton>
));

interface ViewSwitchProps {
  monthly: TabProps;
  yearly: TabProps;
}

// Tablist Aria should implement everyhing in the spec:
// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tablist_role
// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role#example
// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tabpanel_role (views)
// See live example: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role#example

export const ViewSwitch = ({ monthly, yearly }: ViewSwitchProps) => {
  const monthlyTab = useRef<HTMLButtonElement>(null);
  const yearlyTab = useRef<HTMLButtonElement>(null);

  const changeTab = () => {
    const mTab = monthlyTab.current;
    const yTab = yearlyTab.current;

    // Refs can be null. In our case refs are constant, so let's just ignore if either is null
    if (mTab && yTab) {
      if (document.activeElement === mTab) {
        mTab.tabIndex = -1;
        yTab.tabIndex = 0;
        yTab.focus();
      } else if (document.activeElement === yTab) {
        yTab.tabIndex = -1;
        mTab.tabIndex = 0;
        mTab.focus();
      }
    }
  };

  return (
    <GradientBg>
      {/* I think this rule has an issue. Tablist should focus on the first tab, not on itself */}
      {/* eslint-disable-next-line styled-components-a11y/interactive-supports-focus */}
      <Tablist
        role="tablist"
        aria-multiselectable="false"
        aria-label="view mode"
        aria-orientation="horizontal"
        onKeyDown={(e) => {
          if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
            // Next
            changeTab();
          } else if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
            // Previous
            changeTab();
          }
        }}
      >
        <Tab ref={monthlyTab} {...monthly}>
          {t('membership_hub_monthly_report_list')}
        </Tab>
        <Tab ref={yearlyTab} {...yearly}>
          {t('membership_hub_yearly_report_list')}
        </Tab>
      </Tablist>
    </GradientBg>
  );
};
