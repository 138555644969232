import isEmpty from 'lodash/isEmpty';

import { GetAccountResponse } from 'apps-common/graphql/getAccount';
import {
  FlowType,
  MembershipType,
  PaymentMethod,
  PaymentMethodState,
  PaymentMethodType,
  RenewalState,
  SubscriptionState,
} from 'apps-common/types';

export const isB2CMember = (type: GetAccountResponse['account']['membershipType']): type is MembershipType.B2C =>
  type === MembershipType.B2C;

export const isB2BMember = (type: GetAccountResponse['account']['membershipType']): type is MembershipType.B2B =>
  type === MembershipType.B2B;

// isLegacyUser : Gen1 + Gen2
export const isLegacyMember = (
  type: GetAccountResponse['account']['membershipType'],
): type is MembershipType.LEGACY_HW => type === MembershipType.LEGACY_HW;

export const isLifetimeMember = (
  type: GetAccountResponse['account']['membershipType'],
): type is MembershipType.LIFETIME => type === MembershipType.LIFETIME;

export const isEntitlementMember = (type: GetAccountResponse['account']['membershipType']) =>
  isB2BMember(type) || isLifetimeMember(type);

// isGucciRingUser is a type of LIFETIME user
export const isGucciRingUser = (firstName: string) => {
  return firstName === 'gollum';
};

/* returns true if the user is a prepaid member based on current or future prepaid periods given */
export const isPrepaidMember = (
  currentSubscription: GetAccountResponse['account']['currentSubscription'],
  prepaidType: 'current' | 'future',
): boolean => !isEmpty(currentSubscription?.prepaidPeriods?.[prepaidType]);

export const isActiveSubscription = (subscriptionState: SubscriptionState | null | undefined) => {
  return (
    subscriptionState === SubscriptionState.MEMBER ||
    subscriptionState === SubscriptionState.TRIAL ||
    subscriptionState === SubscriptionState.LIFETIME
  );
};

export const isExpiredSubscription = (
  state: SubscriptionState | null | undefined,
): state is SubscriptionState.EXPIRED => state === SubscriptionState.EXPIRED;

export const isPendingSubscription = (
  state: SubscriptionState | null | undefined,
): state is SubscriptionState.PENDING => state === SubscriptionState.PENDING;

export const isTrialSubscription = (state: SubscriptionState | null | undefined): state is SubscriptionState.TRIAL =>
  state === SubscriptionState.TRIAL;

export const isDunningSubscription = (dunningLevel: number | null) => dunningLevel && dunningLevel > 0;

export const isRenewAllowed = (renewalState: RenewalState) => {
  return renewalState !== 'RENEWAL_FORBIDDEN';
};

export const getPaymentMethodStatus = (paymentMethod: PaymentMethod | undefined): PaymentMethodState => {
  if (!paymentMethod) return PaymentMethodState.NO_PAYMENT;

  if (
    (paymentMethod.type === PaymentMethodType.CreditCard && paymentMethod.isExpired) ||
    paymentMethod.status !== 'Active'
  ) {
    return PaymentMethodState.EXPIRED;
  }
  return PaymentMethodState.ACTIVE;
};

export const getFlowType = (currentSubscription: GetAccountResponse['account']['currentSubscription']) =>
  currentSubscription && (isRenewAllowed(currentSubscription.renewalState) ? FlowType.renew : FlowType.normal);

export const ableToSelectPlan = (currentSubscription: GetAccountResponse['account']['currentSubscription']) => {
  const flowType = getFlowType(currentSubscription);
  return flowType === FlowType.renew && !currentSubscription?.pendingCancellation;
};
