import { gql } from 'graphql-request';

import { axiosQuery } from '../utils/axiosQuery';

type CancelSubscriptionOutput = 'CANCELLED' | 'NOT_FOUND' | 'UNABLE_TO_CANCEL_LIFETIME';

const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation CancelSubscription {
    cancelSubscription
  }
`;

export const cancelSubscription = () =>
  axiosQuery<CancelSubscriptionOutput>('CancelSubscription', CANCEL_SUBSCRIPTION_MUTATION);
