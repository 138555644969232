import { Key, ReactNode } from 'react';

import { RowContainer } from 'ui/styles/containers';
import { Paragraph, SectionTitle, Text } from 'ui/styles/text';

export interface RowValue {
  value: ReactNode | RowValue[];
  color?: 'grayLight' | 'lightRed' | 'lightGreen';
  icon?: ReactNode;
}
interface PreviewRowProps {
  label?: ReactNode;
  rowValues: RowValue[];
  showBottomBorder?: boolean;
}

export const PreviewRow = ({ label, rowValues, showBottomBorder = true }: PreviewRowProps) => {
  const renderTextComponent = (content: ReactNode, color: RowValue['color'] | undefined, icon: ReactNode, key: Key) => (
    <>
      {icon}
      <Text
        $fontSize="small"
        $color={color ?? 'grayLight'}
        $lineHeight="140%"
        $margin={icon ? '0 0 0 5px' : '0'}
        key={key}
      >
        {content}
      </Text>
    </>
  );

  const handleRowText = (rowValue: RowValue, idx: number | string): ReactNode => {
    // If the value is an array of RowValue, map each to a Text component
    if (Array.isArray(rowValue.value)) {
      return rowValue.value.map((valueObject, childIdx) => handleRowText(valueObject, `${idx}-${childIdx}`));
    }
    return renderTextComponent(rowValue.value, rowValue.color, rowValue.icon, idx);
  };

  return (
    <RowContainer $border={showBottomBorder} $padding="12px">
      <SectionTitle $lineHeight="150%">{label}</SectionTitle>
      {(rowValues ?? []).map((rowValue, idx) => (
        <Paragraph key={idx}>{handleRowText(rowValue, idx)}</Paragraph>
      ))}
    </RowContainer>
  );
};
