import { useRef, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import { passwordRegex } from 'common-utils';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { t } from 'translations';

import { resetPassword } from 'apps-common/hooks/AccountApi';
import { useQueryParams } from 'apps-common/hooks/useQueryParams';
import { AccountApiError } from 'apps-common/utils/AccountApiError';
import { throwError } from 'apps-common/utils/errorHandler';
import { CTALoader, ErrorBanner, Header, PasswordField, SubmitButton } from 'ui';
import { Dialog } from 'ui/components/Dialog';
import { ErrorIcon } from 'ui/components/Icons';
import { MainContainer } from 'ui/styles/containers';
import { SmallText } from 'ui/styles/text';

import { PasswordSuccessDialog } from '../../components/PasswordSuccessDialog';
import { routes } from '../../routes';

import { StyledForm } from './StyledForm';

interface ResetPasswordFormData {
  password: string;
}

type FormValues = ResetPasswordFormData & {
  serverError?: string;
};

export const ResetPasswordPage = () => {
  const query = useQueryParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const token = query.get('token');
  const [showPassword, setShowPassword] = useState(false);
  const pwDialogRef = useRef<HTMLDialogElement>(null);
  const errorDialogRef = useRef<HTMLDialogElement>(null);
  const { clearErrors, formState, handleSubmit, register, setError } = useForm<FormValues>({
    criteriaMode: 'all',
    mode: 'onTouched',
  });

  if (!token) {
    throwError('missingResetPasswordToken');
  }

  const { mutate: resetPWMutate, isPending } = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: resetPassword,
  });
  const onSubmit = ({ password }: ResetPasswordFormData) => {
    resetPWMutate(
      { token: token, password },
      {
        onSuccess: () => {
          pwDialogRef.current?.showModal();
        },
        onError: (error) => {
          if (error instanceof AccountApiError && error.code === 'OneTimeTokenUsed') {
            errorDialogRef.current?.showModal();
          } else {
            setError('serverError', { type: 'server', message: error.message });
          }
        },
      },
    );
  };

  const magicLinkExpiredDialog = (
    <Dialog
      ref={errorDialogRef}
      icon={<ErrorIcon />}
      title={t('membership_hub_try_again_error')}
      textContents={[t('membership_hub_pw_reset_link_expired')]}
      primaryButton={{
        text: t('membership_hub_return_to_hub_home'),
        onClick: () => navigate(routes.index),
      }}
    />
  );
  const SUB_TITLE = t('membership_hub_update_membership_password');
  return (
    <>
      <Header pageType="create" appType="hub" title={t('membership_hub_reset_password')} subTitle={SUB_TITLE} />
      <MainContainer $padding="10px 0 0 0">
        {formState.errors.serverError && (
          <ErrorBanner marginBottom="8px">{formState.errors.serverError.message}</ErrorBanner>
        )}
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          onFocus={() => {
            clearErrors('serverError');
          }}
        >
          <PasswordField
            showPassword={showPassword}
            onRevealIconClick={() => setShowPassword(!showPassword)}
            placeholder={intl.formatMessage({
              id: 'membership_hub_enter_new_password',
            })}
            {...register('password', {
              required: {
                value: true,
                message: intl.formatMessage({
                  id: 'membership_hub_password_required_error_message',
                }),
              },
              pattern: {
                value: passwordRegex,
                message: intl.formatMessage({
                  id: 'membership_error_password_requirements',
                }),
              },
            })}
            error={formState.errors.password?.message}
          />
          <SmallText $fontSize="xsmall" $margin="10px">
            {t('membership_hub_pw_creation_requirement_policy')}
          </SmallText>

          <SubmitButton disabled={!formState.isValid || isPending}>
            {isPending ? <CTALoader /> : t('membership_hub_reset_password')}
          </SubmitButton>
        </StyledForm>
        <PasswordSuccessDialog dialogRef={pwDialogRef} />
        {magicLinkExpiredDialog}
      </MainContainer>
    </>
  );
};
