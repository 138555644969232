import { styled } from 'styled-components';

export const Ball = styled.div<{ $color: 'lightGreen' | 'lightRed' }>(
  ({ theme, $color }) => `
  width: 8px;
  height: 8px;
  background: ${theme.colors[$color]};
  border-radius: 50%;
  flex-shrink: 0;
`,
);
