import { useIntl } from 'react-intl';
import { styled } from 'styled-components';

import { LinkSection, LinkTextColor } from 'ui/components/LinkSection';

interface NavigationRowProps {
  label: string;
  color?: LinkTextColor;
  href?: string;
  onClick?: () => void;
  target?: string;
}

// only navigationRow has hover
const Navigation = styled(LinkSection)(
  ({ theme }) => `
  padding: 14px 16px;
  cursor: pointer;
  height: 47px;
  border-bottom: 1px solid ${theme.colors.grayMedium};

  &:hover {
    background-color: ${theme.colors.grayMedium};
  }

  &:last-child {
    border-bottom: none;
  }

  &:first-child:hover {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  &:last-child:hover {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
`,
);

export const NavigationRow = ({ label, onClick, href, color = 'blueLight', target }: NavigationRowProps) => {
  const intl = useIntl();
  const ariaLabel = intl.formatMessage({ id: 'membership_hub_accessibility_navigate_to' }, { link: label });

  return (
    <Navigation onClick={onClick} href={href} color={color} target={target} aria-label={ariaLabel}>
      {label}
    </Navigation>
  );
};
