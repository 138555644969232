import { ReactElement } from 'react';

import { styled } from 'styled-components';

import { HeaderText, Paragraph } from 'ui/styles/text';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding-top: 50px;
`;
const StyledIcon = styled.div`
  display: flex;
  width: 47px;
  height: 47px;
  padding: 13px 17px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 0.667px solid rgba(255 255 255 / 20%);
`;
interface FeatureProps {
  header: ReactElement;
  text: ReactElement;
  icon: ReactElement;
}
export const FeatureText = ({ header, text, icon }: FeatureProps) => {
  return (
    <StyledContainer>
      <StyledIcon>{icon}</StyledIcon>
      <HeaderText>{header}</HeaderText>
      <Paragraph>{text}</Paragraph>
    </StyledContainer>
  );
};
