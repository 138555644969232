import uniq from 'lodash/uniq';

import { isStrictNever } from 'apps-common/utils/isStrictNever';

interface AddAction {
  type: 'add';
  filesToAdd: string[];
}

interface DeleteAction {
  type: 'delete';
  fileToDelete: string;
}

interface ClearAction {
  type: 'clear';
}

export type FileSelectionAction = AddAction | DeleteAction | ClearAction;

export const fileSelectionReducer = (files: string[], action: FileSelectionAction) => {
  switch (action.type) {
    case 'add': {
      return uniq([...files, ...action.filesToAdd]);
    }
    case 'delete': {
      return files.filter((f) => f !== action.fileToDelete);
    }
    case 'clear': {
      return [];
    }
    default: {
      isStrictNever(action);
    }
  }
};
