import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { t } from 'translations';

import { PaymentDisclaimer } from 'apps-common/components/PaymentDisclaimer';
import { ProductSelector } from 'apps-common/components/ProductSelector';
import { useGetAccount } from 'apps-common/hooks/useGetAccount';
import { BillingPeriod } from 'apps-common/types';
import { track } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { getRatePlansForCountry, ratePlansFromSubscription } from 'apps-common/utils/getProduct';
import { logger } from 'apps-common/utils/logger';
import { Form, Header, Loader, SubmitButton } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { routes } from '../routes';
import { useStore } from '../store';
import { isExpiredSubscription } from '../utils/member';

// only for expired users to select a plan while renewing
export const RenewSelectPlanPage = () => {
  const { data: accountData, error, isError, isFetching } = useGetAccount();
  const navigate = useNavigate();

  const userAddressForm = useStore((state) => state.userAddressForm);

  const selectedRatePlan = useStore((state) => state.selectedRatePlan);
  const setRatePlan = useStore((state) => state.setRatePlan);

  const existingAddressInUse = useStore((state) => state.existingAddressInUse);

  const { formState, handleSubmit, register } = useForm<{
    product: BillingPeriod;
  }>({
    values: {
      product: selectedRatePlan?.billingPeriod ?? BillingPeriod.Months,
    },
  });

  const title = t('membership_hub_product_page_title');
  const subTitle = t('membership_hub_product_page_subtitle');

  if (isFetching) {
    return (
      <>
        <Header
          pageType="create"
          appType="hub"
          title={title}
          subTitle={subTitle}
          onBackClick={() => navigate(routes.addressForm)}
        />
        <MainContainer>
          <Loader />
        </MainContainer>
      </>
    );
  }

  if (isError || !accountData) {
    throwError('errorOnGetAccount', error);
  }

  const {
    account: { currentSubscription },
    membershipOffering: { products: catalogProducts },
  } = accountData;

  if (!currentSubscription) {
    throwError('hubGeneralError', 'No subscription found for renewing user.');
  }

  const ratePlans =
    !isExpiredSubscription(currentSubscription.subscriptionState) && currentSubscription.recurringFee
      ? ratePlansFromSubscription(currentSubscription)
      : getRatePlansForCountry(catalogProducts, userAddressForm!.shippingAddress.country);

  const onSubmit = handleSubmit((data) => {
    track({
      event: 'CTA Clicked',
      payload: {
        cta: 'next',
        action: 'renew_next_step',
        step: 'plan_select',
      },
    });

    const { product: billingPeriod } = data;
    const ratePlan = ratePlans.find((rp) => rp.billingPeriod === billingPeriod)!;
    setRatePlan(ratePlan);

    track({
      event: 'Membership Plan Selected',
      payload: {
        productType: ratePlan.billingPeriod,
      },
    });

    logger.info('Plan selected in renew flow, continuing to payment method page');
    navigate(routes.updatePaymentMethod);
  });

  const backRoute = existingAddressInUse ? routes.SelectAddressUsage : routes.addressForm;

  return (
    <>
      <Header
        pageType="create"
        appType="hub"
        title={title}
        subTitle={subTitle}
        onBackClick={() => navigate(backRoute)}
      />
      <MainContainer>
        <Form onSubmit={onSubmit}>
          <ProductSelector ratePlans={ratePlans} {...register('product', { required: true })} />
          <SubmitButton disabled={!formState.isValid}>{t('membership_signup_button_next')}</SubmitButton>
          <PaymentDisclaimer />
        </Form>
      </MainContainer>
    </>
  );
};
