import { useReducer, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { t } from 'translations';

import { invoicesToAnnualReport } from 'apps-common/types';
import { Flags, useFlag } from 'apps-common/utils/featureFlags';
import { logger } from 'apps-common/utils/logger';
import { Checkbox, ErrorBanner, Header, Loader } from 'ui';
import { MainContainer } from 'ui/styles/containers';
import { Row } from 'ui/styles/layout';
import { Paragraph } from 'ui/styles/text';

import { useGetBillingHistory } from '../../hooks/useGetBillingHistory';
import { routes } from '../../routes';

import { AnnualReportView } from './AnnualReportView';
import { fileSelectionReducer } from './fileSelectionReducer';
import { InvoiceView } from './InvoiceView';
import { ViewSwitch } from './ViewSwitch';

const NoInvoicesText = styled(Paragraph)`
  width: 60%;
  text-align: center;
  margin: 100px 0;
`;

const PaddedRow = styled(Row)`
  align-items: center;
  margin-top: 24px;
  padding: 16px;
  width: 100%;
`;

export const ViewBillingHistoryPage = () => {
  const { data, error, isFetching } = useGetBillingHistory();
  const annualReportFlag = useFlag(Flags.ANNUAL_REPORTS);
  const navigate = useNavigate();
  if (error) {
    logger.error('Fetching billing history failed', { error });
  }

  const [selectedFiles, dispatch] = useReducer(fileSelectionReducer, []);

  const [selectedView, setSelectedView] = useState<'monthly' | 'yearly'>('monthly');
  const invoiceViewId = 'monthly-view';
  const annualViewId = 'annual-report-view';

  if (isFetching || !data) {
    return <Loader />;
  }

  const {
    invoices,
    account: { currency },
  } = data;

  const annualReports = invoicesToAnnualReport(invoices);

  const files = selectedView === 'yearly' ? annualReports : invoices;
  const allChecked = selectedFiles.length === files.length;

  return (
    <>
      <Header
        appType="hub"
        pageType="create"
        title={t('membership_hub_billing_history_header')}
        onBackClick={() => navigate(routes.index)}
      />
      <MainContainer $padding="10px 0" $maxWidth="794px">
        {error && <ErrorBanner>{error.message}</ErrorBanner>}

        {invoices.length === 0 && <NoInvoicesText>{t('membership_hub_no_billing_history')}</NoInvoicesText>}

        {!isEmpty(invoices) && (
          <>
            <PaddedRow>
              <Checkbox
                $color="white2"
                checked={allChecked}
                onChange={(e) => {
                  const { checked } = e.target;
                  if (checked) {
                    const allIds = files.map((f) => f.id);
                    dispatch({ type: 'add', filesToAdd: allIds });
                  } else {
                    dispatch({ type: 'clear' });
                  }
                }}
              >
                {t('membership_hub_select_all', {
                  number_of_invoices: files.length,
                })}
              </Checkbox>
              {annualReportFlag && (
                <ViewSwitch
                  monthly={{
                    onClick: () => {
                      if (selectedView !== 'monthly') {
                        dispatch({ type: 'clear' });
                        setSelectedView('monthly');
                      }
                    },

                    'aria-controls': invoiceViewId,
                    selected: selectedView === 'monthly',
                  }}
                  yearly={{
                    onClick: () => {
                      if (selectedView !== 'yearly') {
                        dispatch({ type: 'clear' });
                        setSelectedView('yearly');
                      }
                    },
                    'aria-controls': annualViewId,
                    selected: selectedView === 'yearly',
                  }}
                />
              )}
            </PaddedRow>

            {selectedView === 'monthly' && (
              <InvoiceView
                id={invoiceViewId}
                invoices={invoices}
                currency={currency}
                selectedInvoices={selectedFiles}
                dispatch={dispatch}
              />
            )}
            {selectedView === 'yearly' && (
              <AnnualReportView
                id={annualViewId}
                annualReports={annualReports}
                currency={currency}
                selectedReports={selectedFiles}
                dispatch={dispatch}
              />
            )}
          </>
        )}
      </MainContainer>
    </>
  );
};
