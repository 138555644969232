import { useSearchParams } from 'react-router-dom';

import { useCompleteLogin, useInitLogin } from 'apps-common/hooks/AccountApi';
import { identify, setUserId } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { logger } from 'apps-common/utils/logger';
import { Loader } from 'ui';

interface LoginFormProps {
  onLogin: () => void;
}

const MoiLogin = ({ onLogin }: LoginFormProps) => {
  // initial login landing and redirected page back to login from MOI
  const [searchParams] = useSearchParams();
  logger.info('Moi login started', { auth: 'moi' });
  const error = searchParams.get('error') ?? undefined;
  const errorDescription = searchParams.get('error_description') ?? undefined;

  if (error) {
    logger.error('Moi login failed', {
      auth: 'moi',
      reason: 'Error in login: ' + error + ' : ' + errorDescription,
    });
    throwError('moiLoginError', errorDescription);
  }

  const redirectUri = window.location.href.split('?')[0];
  const state = searchParams.get('state') ?? '';
  const code = searchParams.get('code') ?? '';

  // redirects user to MOI page where entering user account email + one-time code from email
  const { data: initData, isFetching } = useInitLogin(redirectUri, !(state && code));

  // once user enters one-time code, completes login process by exchanging the code and state for an access token
  const { data: endLoginData, isFetching: isFetchingLoginEnd } = useCompleteLogin(
    redirectUri,
    code,
    state,
    state !== '' && code !== '',
  );

  if (isFetching || isFetchingLoginEnd) {
    return <Loader />;
  }
  if (initData?.authorizationRequestUrl) {
    window.location.href = initData.authorizationRequestUrl;
  }

  if (endLoginData) {
    setUserId(endLoginData.analyticsId);
    identify({ traits: { isHipaa: endLoginData.isHipaa } });
    onLogin();
    logger.info('Moi login completed', { auth: 'moi' });
    window.location.href = '/';
  }

  return null;
};

export default MoiLogin;
